"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WIDGET_ERROR_MESSAGE_MAP = exports.WidgetErrorType = exports.InputEvents = exports.Events = exports.WidgetParamsEnum = exports.WidgetModes = void 0;
var WidgetModes;
(function (WidgetModes) {
    WidgetModes["SelectObject"] = "selectObject";
    WidgetModes["Upload"] = "upload";
})(WidgetModes = exports.WidgetModes || (exports.WidgetModes = {}));
var WidgetParamsEnum;
(function (WidgetParamsEnum) {
    WidgetParamsEnum["locale"] = "locale";
    WidgetParamsEnum["maximumSize"] = "maximumSize";
})(WidgetParamsEnum = exports.WidgetParamsEnum || (exports.WidgetParamsEnum = {}));
var Events;
(function (Events) {
    Events["Ready"] = "Ready";
    Events["Error"] = "Error";
    Events["Selection"] = "Selection";
    Events["PublicLink"] = "PublicLink";
    Events["Upload"] = "Upload";
    Events["Remove"] = "Remove";
    Events["UploadFiles"] = "UploadFiles";
    Events["CreatePublicLinks"] = "CreatePublicLinks";
})(Events = exports.Events || (exports.Events = {}));
var InputEvents;
(function (InputEvents) {
    InputEvents["UploadFiles"] = "UploadFiles";
    InputEvents["CreatePublicLinks"] = "CreatePublicLinks";
})(InputEvents = exports.InputEvents || (exports.InputEvents = {}));
var WidgetErrorType;
(function (WidgetErrorType) {
    WidgetErrorType["INVALID_MODE"] = "widget_invalid_mode";
    WidgetErrorType["INVALID_PARAMS"] = "widget_invalid_params";
    WidgetErrorType["INVALID_TOKEN"] = "widget_invalid_token";
    WidgetErrorType["EXPIRE_TOKEN"] = "expire_token";
})(WidgetErrorType = exports.WidgetErrorType || (exports.WidgetErrorType = {}));
exports.WIDGET_ERROR_MESSAGE_MAP = {
    [WidgetErrorType.INVALID_PARAMS]: 'Invalid options',
    [WidgetErrorType.INVALID_MODE]: 'Invalid mode',
    [WidgetErrorType.INVALID_TOKEN]: 'Invalid token',
    [WidgetErrorType.EXPIRE_TOKEN]: 'Token is expire',
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WidgetApi = void 0;
const uuid_1 = require("uuid");
const types_1 = require("./types");
class WidgetApi {
    container;
    options;
    #iframe;
    #widgetSettings;
    #subscribes = new Map();
    #unsubscriptions = [];
    #widgetOption;
    constructor(container, options) {
        this.container = container;
        this.options = options;
        this.#iframe = this.#createFrame();
        const optionValidator = WidgetApi.validateWidgetOptions(options);
        if (!optionValidator.isValid) {
            throw `Invalid widget initial options. ${types_1.WIDGET_ERROR_MESSAGE_MAP[optionValidator.error_type]}`;
        }
        this.#widgetOption = options;
        this.#initListener();
    }
    destroyer() {
        this.#unsubscriptions.forEach((unsubscription) => unsubscription());
        window.removeEventListener('message', this.#handlePostMessage);
    }
    getCurrentSettings() {
        return this.#widgetSettings;
    }
    getOptions() {
        return this.#widgetOption;
    }
    emitEventToWidget(event, payload) {
        if (this.#iframe?.contentWindow) {
            const message = {
                isWfmWidgetMessage: true,
                payload,
                type: event,
            };
            const { origin } = this.getOptions();
            this.#iframe.contentWindow.postMessage(message, origin);
        }
    }
    subscribeOn(event, callback) {
        let set = this.#subscribes.get(event);
        if (!set) {
            set = new Set();
            this.#subscribes.set(event, set);
        }
        set.add(callback);
        return () => {
            const subscribes = this.#subscribes.get(event);
            if (!subscribes) {
                return;
            }
            subscribes.delete(callback);
        };
    }
    #initListener() {
        window.addEventListener('message', this.#handlePostMessage);
        this.#unsubscriptions.push(this.subscribeOn(types_1.Events.Ready, (settings) => (this.#widgetSettings = settings)));
    }
    #handlePostMessage = (event) => {
        if (!event.data.isWfmWidgetMessage) {
            return;
        }
        const data = event.data;
        const subscribes = this.#subscribes.get(data.type);
        if (subscribes) {
            subscribes.forEach((cb) => cb(data.payload));
        }
    };
    #createFrame() {
        const iframe = document.createElement('iframe');
        iframe.src = WidgetApi.formWidgetUrl(this.options);
        iframe.id = (0, uuid_1.v4)();
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        this.container.appendChild(iframe);
        return iframe;
    }
    static formWidgetUrl({ mode, params, token, fileBaseUrl }) {
        const query = [];
        for (const prop in params) {
            if (params.hasOwnProperty(prop)) {
                query.push(prop + '=' + params[prop]);
            }
        }
        return `${fileBaseUrl}/widget/${mode}/?${query.join('&')}#token=${token}`;
    }
    static validateWidgetOptions({ mode, token, params }) {
        const modeIsValid = Object.values(types_1.WidgetModes).includes(mode);
        if (!modeIsValid) {
            return { isValid: false, error_type: types_1.WidgetErrorType.INVALID_MODE };
        }
        const paramsIsValid = Object.keys(params).every((param) => Object.keys(types_1.WidgetParamsEnum).includes(param));
        if (!paramsIsValid) {
            return { isValid: false, error_type: types_1.WidgetErrorType.INVALID_PARAMS };
        }
        if (!token) {
            return { isValid: false, error_type: types_1.WidgetErrorType.INVALID_TOKEN };
        }
        return { isValid: true };
    }
}
exports.WidgetApi = WidgetApi;
